<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span
              >商务名称：
              <el-autocomplete
                class="ipt_width project_width"
                v-model="parameter.businessName"
                :fetch-suggestions="businessNameQuerySearch"
                placeholder="请输入或选择商务名称"
                @select="onProkectNameSelect"
                :popper-append-to-body="false"
              >
              </el-autocomplete
            ></span>
            <span
              >商务类型：
              <Dictionary
                v-model="parameter.businessType"
                class="ipt_width"
                code="BUSINESS_TYPE"
                placeholder="请选择商务类型"
            /></span>
            <span>
              商务负责人：
              <el-select
                class="ipt_width"
                clearable
                v-model="parameter.principalUserId"
                filterable
                placeholder="请输入或者选择"
              >
                <el-option
                  v-for="item in selectedList"
                  :key="item.id"
                  :label="item.userName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </span>
            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset"> 清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="dialogBusiness = true">商务流程</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="activeName" class="fullScreenMainHeader">
            <el-tab-pane label="待审核"></el-tab-pane>
            <el-tab-pane label="已审核"></el-tab-pane>
          </el-tabs>

          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              prop="businessName"
              min-width="320"
              :show-overflow-tooltip="false"
              label="商务名称"
            ></el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              min-width="220"
              prop="ownerUnit"
              label="业主单位"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="contacts"
              label="经办人"
              width="85"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="contactsPhone"
              width="120"
              label="经办人电话"
            ></el-table-column>
            <el-table-column
              align="center"
              width="110"
              sortable="custom"
              prop="createdDate"
              label="填报时间"
            >
              <template slot-scope="scope">
                {{ scope.row.createdDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="110"
              sortable="custom"
              prop="businessStartDate"
              label="商务实际开始时间"
            >
              <template slot-scope="scope">
                {{ scope.row.businessStartDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              sortable
              width="120"
              prop="businessType"
              label="商务类型"
            >
              <template slot-scope="scope">
                {{ scope.row.businessType | dict(dictData.businessType) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="status" sortable width="160" label="状态">
              <template slot-scope="scope">
                {{ scope.row.status | dict(dictData.businessStauts) }}
              </template>
            </el-table-column>

            <el-table-column prop="title" fixed="right" align="center" label="操作" width="110">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Examine_Bgc"
                  @click.stop="shenhe(scope.row)"
                  v-if="activeName == '0'"
                  >审批</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click="detail(scope.row)"
                  v-if="activeName == '1'"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="text_Remove_Bgc"
                  v-if="permission(['SWXX_DELETE'])"
                  @click.stop="delFn(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <Edit v-if="showEditDialog" :isShow.sync="showEditDialog" :options="form"></Edit>
    <Principaluser width="600px" :principal="form" v-show="showzhipai"></Principaluser>
    <el-dialog title="商务流程图" :visible.sync="dialogBusiness" width="75%">
      <img src="@/assets/商务流程图.png" alt="商务流程图" width="100%" />
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('./Edit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    Principaluser: () => import('./Principaluser.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        principalUserId: null,
        businessName: null,
        businessType: null,
      },
      pageSize: 0,
      form: {},
      tableData: [],
      loading: false,
      showEditDialog: false,
      activeName: '',
      dictData: {
        businessType: [],
        businessStauts: [],
      },
      showzhipai: false,
      businessList: [],
      selectedList: [],
      dialogBusiness: false,
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      allUserList: state => state.business.allUserList,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.$api.dict
      .listSysDictData('ALL_BUSINESSMANAGE_TYPE', true)
      .then(res => {
        this.dictData.businessStauts = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_TYPE', true)
      .then(res => {
        this.dictData.businessType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.businessExpense
      .businessNameList()
      .then(res => {
        this.businessList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    if (this.allUserList.length <= 0) {
      this.getSelectedList()
    } else {
      this.selectedList = this.allUserList
    }
  },
  watch: {
    activeName: {
      // immediate:true,//初始化立即执行
      handler: function (newVal, oldVal) {
        this.parameter.pageNow = 1
        this.getData()
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  methods: {
    businessNameQuerySearch(queryString, cb) {
      let businessList = this.businessList.map(item => {
        return { value: item.businessName }
      })
      let results = queryString ? this.businessNameCreateFilter(queryString) : businessList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    businessNameCreateFilter(queryString) {
      let results = this.businessList.map(item => {
        return { value: item.businessName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.businessName = e.value
      }
      this.$forceUpdate()
    },
    // 获取所有人员
    getSelectedList() {
      this.$api.businessManage
        .principalUserNameListNoPage()
        .then(res => {
          this.selectedList = res.data
          this.$store.commit({
            type: 'business/SET_ALL_USER_LIST',
            allUserList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    delFn(id) {
      this.$confirm('此操作将删除该商务信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loading = true
          this.$api.businessManage
            .deleteBusiness(id)
            .then(res => {
              this.loading = false
              this.getData()
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    getData() {
      this.loading = true
      this.parameter.status = this.activeName
      this.$api.businessManage
        .getBusinessManageList(this.parameter)
        .then(res => {
          this.loading = false
          if (!res.data) {
            this.tableData = []
            this.parameter.total = 0
          } else {
            this.tableData = res.data.records
            this.parameter.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    add() {
      this.form = {}
      this.showEditDialog = true
    },
    shenhe(row) {
      this.form = row
      this.form.list_s = 1 //审批
      this.showEditDialog = true
    },
    detail(row) {
      this.form = row
      this.form.list_s = 2 //详情
      this.showEditDialog = true
    },
    re() {
      this.getData()
      this.showzhipai = false
      this.showEditDialog = false
    },
    handleReset() {
      this.parameter = {
        pageNow: 1,
        pageSize: this.pageSizeLog,
        total: 0,
        principalUserId: null,
        businessName: null,
        businessType: null,
      }
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
